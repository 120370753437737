<!--
 * @Description: 任务单质检(一期不做)
 * @Author: ChenXueLin
 * @Date: 2021-09-16 11:15:00
 * @LastEditTime: 2022-07-15 16:45:43
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="质检ID"
                title="质检ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="调度信息ID"
                title="调度信息ID"
                maxlength="10"
                @input="
                  searchForm.orderNo = isNaN(searchForm.orderNo)
                    ? searchForm.orderNo.replace(/[^\d]/g, '')
                    : searchForm.orderNo
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="任务单号"
                title="任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="完成时间"
                title="完成时间"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="工程师名称"
                title="工程师名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="工程师电话"
                title="工程师电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="质检状态"
                title="质检状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="提交时间"
                title="提交时间"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="质检员"
                title="质检员"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      total: 0,
      editDialog: false,
      editDialogLoading: false,
      searchForm: {
        typeId: "",
        id: ""
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "质检ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "工单ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "任务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userType",
          display: true,
          fieldLabel: "完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "role",
          display: true,
          fieldLabel: "工程师名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "company",
          display: true,
          fieldLabel: "所属企业",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "department",
          display: true,
          fieldLabel: "所属部门",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "job",
          display: true,
          fieldLabel: "职位",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "用户状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      addUserVisible: false,
      addUserForm: {},
      editCustomerDialogLoading: false,
      addUserRules: {}
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val) {
      if (val.id == 1) {
        this.routerPush({
          name: "qualityInspectionCenter/taskOrderInspectionDetail",
          params: {
            refresh: true
          }
        });
      }
    }
  },

  created() {
    console.log(this.resizeViewHeight);
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
